var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('page-title',{attrs:{"title":_vm.storeName}})],1),_c('tabs',{staticClass:"mb-4",attrs:{"options":_vm.tabOptions},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}}),_c('v-container',[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabIndex == 0),expression:"tabIndex == 0"}],attrs:{"elevation":"0"}},[_c('v-card-text',{staticClass:"pa-0"},[_c('store-info-readonly',{on:{"setFormKey":_vm.setFormKey}}),(
              _vm.status ==
              _vm.$providerStoreCooperation.STORE_RELATIONSHIP_STATUS_REJECT
            )?_c('div',{staticClass:"mt-10"},[_c('p',{staticClass:"mb-2"},[_vm._v("拒絕原因：")]),_c('p',[_vm._v(_vm._s(_vm.reason))])]):_vm._e()],1),_c('v-card-actions',{staticClass:"pa-0"},[(
              _vm.status ==
              this.$providerStoreCooperation.STORE_RELATIONSHIP_STATUS_REQUEST
            )?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('updateCooperation',{attrs:{"type":"reject","providerId":_vm.providerId,"storeId":_vm.storeId,"name":_vm.storeName,"routeName":"client-status-reject"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('updateCooperation',{attrs:{"type":"accept","providerId":_vm.providerId,"storeId":_vm.storeId,"name":_vm.storeName,"routeName":"client-status-accept"}})],1)],1):_vm._e(),(
              _vm.status ==
              this.$providerStoreCooperation
                .STORE_RELATIONSHIP_STATUS_COOPERATION
            )?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}}),_c('v-col',{attrs:{"cols":"6"}},[_c('updateCooperation',{attrs:{"type":"cancel","providerId":_vm.providerId,"storeId":_vm.storeId,"name":_vm.storeName,"routeName":"client-status-cancel"}})],1)],1):_vm._e(),(
              _vm.status ==
              this.$providerStoreCooperation.STORE_RELATIONSHIP_STATUS_REJECT
            )?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}}),_c('v-col',{attrs:{"cols":"6"}},[_c('updateCooperation',{attrs:{"type":"reApproved","providerId":_vm.providerId,"storeId":_vm.storeId,"name":_vm.storeName,"routeName":"client-status-accept"}})],1)],1):_vm._e()],1)],1)],1),_c('div',[(_vm.tabIndex == 1)?_c('div',[_c('client-store-order-list',{attrs:{"storeId":_vm.storeId}})],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }