<template>
  <div>
    <v-container>
      <page-title :title="storeName"></page-title>
    </v-container>
    <tabs v-model="tabIndex" :options="tabOptions" class="mb-4" />

    <!-- 客戶資訊 -->
    <v-container>
      <v-card v-show="tabIndex == 0" elevation="0">
        <v-card-text class="pa-0">
          <store-info-readonly @setFormKey="setFormKey" />

          <!-- 拒絕原因 -->
          <div
            v-if="
              status ==
              $providerStoreCooperation.STORE_RELATIONSHIP_STATUS_REJECT
            "
            class="mt-10"
          >
            <p class="mb-2">拒絕原因：</p>
            <p>{{ reason }}</p>
          </div>
        </v-card-text>

        <v-card-actions class="pa-0">
          <v-row
            v-if="
              status ==
              this.$providerStoreCooperation.STORE_RELATIONSHIP_STATUS_REQUEST
            "
          >
            <v-col cols="6">
              <updateCooperation
                type="reject"
                :providerId="providerId"
                :storeId="storeId"
                :name="storeName"
                routeName="client-status-reject"
              />
            </v-col>
            <v-col cols="6">
              <updateCooperation
                type="accept"
                :providerId="providerId"
                :storeId="storeId"
                :name="storeName"
                routeName="client-status-accept"
              />
            </v-col>
          </v-row>

          <v-row
            v-if="
              status ==
              this.$providerStoreCooperation
                .STORE_RELATIONSHIP_STATUS_COOPERATION
            "
          >
            <v-col cols="6">
<!--              <back />-->
            </v-col>
            <v-col cols="6">
              <updateCooperation
                type="cancel"
                :providerId="providerId"
                :storeId="storeId"
                :name="storeName"
                routeName="client-status-cancel"
              />
            </v-col>
          </v-row>

          <v-row
            v-if="
              status ==
              this.$providerStoreCooperation.STORE_RELATIONSHIP_STATUS_REJECT
            "
          >
            <v-col cols="6">
<!--              <back />-->
            </v-col>
            <v-col cols="6">
              <updateCooperation
                type="reApproved"
                :providerId="providerId"
                :storeId="storeId"
                :name="storeName"
                routeName="client-status-accept"
              />
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-container>
    <!-- 歷史訂單 -->
    <div>
      <div v-if="tabIndex == 1">
        <client-store-order-list :storeId="storeId" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    storeInfoReadonly: () =>
      import("@/modules/store/views/storeInfoReadonly.vue"),
    // back: () => import("@/modules/base/components/statusBtn/back.vue"),
    updateCooperation: () =>
      import("@/modules/base/components/statusBtn/updateCooperation.vue"),
    clientStoreOrderList: () =>
      import("@/modules/provider/views/clientStoreOrderList.vue"),
  },
  data: () => ({
    tabIndex: 0,
    formKey: "",
    firstTime: true,
    vipFirstTime: true,
  }),
  computed: {
    formData() {
      return this.$store.getters[`form/${this.formKey}/data`];
    },
    paymentType() {
      if (!this.formData) return null;
      return this.formData.pivot.payment_type;
    },
    vipId() {
      if (!this.formData) return null;
      return this.formData.vip_id;
    },
    status() {
      if (!this.formData) return "";
      return this.formData.pivot.status;
    },
    isCooperation() {
      return (
        this.status ==
        this.$providerStoreCooperation.STORE_RELATIONSHIP_STATUS_COOPERATION
      );
    },
    providerId() {
      return this.$store.getters["member/providerId"];
    },
    storeId() {
      return this.$route.params.storeId;
    },
    storeName() {
      if (!this.formData) return "";
      return this.formData.name;
    },
    tabOptions() {
      if (this.isCooperation)
        return [{ text: "客戶資訊" }, { text: "歷史訂單" }];
      return [{ text: "客戶資訊" }];
    },
    reason() {
      if (!this.formData) return null;
      return this.formData.pivot.reason;
    },
  },
  watch: {
    paymentType() {
      this.changePayment();
    },
    vipId(n, o) {
      this.changeVip(Boolean(o))
    }
  },
  methods: {
    setFormKey(key) {
      this.formKey = key;
    },
    async changePayment() {
      if (this.firstTime) {
        this.firstTime = false;
        return;
      }
      const params = {
        payment_type: this.paymentType,
      };
      this.$store.dispatch("loading/active");
      try {
        const res = await this.$api.collection.providerApi.changePayment(
          this.providerId,
          this.storeId,
          params
        );
        this.$snotify.success("更新付款方式成功");
      } catch (err) {
        console.error(err);
        this.$snotify.error("更新付款方式失敗");
      } finally {
        this.$store.dispatch("loading/close");
      }
    },
    async changeVip(isUpdate = true) {
      if (this.vipFirstTime) {
        this.vipFirstTime = false;
        return;
      }
      const action = isUpdate ? 'updateStore' : 'addStore'
      const params = {
        vip_group_id: this.vipId,
      };
      this.$store.dispatch("loading/active");
      try {
        const res = await this.$api.collection.vipApi[action](
          this.providerId,
          this.storeId,
          params
        );
        this.$snotify.success("更新分級成功");
      } catch (err) {
        console.error(err);
        this.$snotify.error("更新分級失敗");
      } finally {
        this.$store.dispatch("loading/close");
      }
    },
  },
};
</script>